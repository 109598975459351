<template>
  <transportation-profile-component />
</template>

<script>
import TransportationProfileComponent from "@/components/transports/TransportationProfileComponent";

export default {
  name: "TransportationProfile",
  title: "Perfil del Transporte | Baja California Health Tourism",
  components: { TransportationProfileComponent },
};
</script>

<style scoped></style>
